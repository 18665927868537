/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () { // JavaScript to be fired on all pages
        //console.log('init all');

        var topMenuBtn = $('.mobileMenuLink'),
          overlayMenu = $('.overlayMenu'),
          overlayMenuBlockContainer = overlayMenu.find('.menuBlockContainer'),
          overlayMenuBlocks = overlayMenu.find('.menuBlock'),
          bttBtn = $('.bttArrow a');

        topMenuBtn.on('click', function (e) {
          e.preventDefault();
          var self = $(this),
            winW = $(window).width();

          if (self.hasClass('closeBtn')) {
            overlayMenu.fadeOut();
            overlayMenuBlocks.removeClass('opened');
          }
          else {
            overlayMenu.fadeIn();
            overlayMenuBlocks.addClass('opened');

            if (winW > 768) {
              UTIL.setHeight(overlayMenuBlockContainer);
            } else {
              UTIL.setHeight(overlayMenuBlockContainer, true);
            }
          }

        });

        UTIL.initHeaderSlider();

        UTIL.setImagesSrcset();

        UTIL.loadContactMap();

        bttBtn.anchorAnimate({urlChange: false});

      },
      finalize: function () { // JavaScript to be fired on all pages, after page specific JS is fired
        //console.log('finalize all');

        UTIL.anchorScrollDetection();
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        //console.log('init home');
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        //console.log('finalize home');
      }
    },

    //Template contacto
    'page_template_template_contact': {
      init: function () {

        var $mapBoxes = $('.mapBox');

        $mapBoxes.each(function () {
          var $self = $(this),
            _lat = parseFloat($self.attr('data-lat')),
            _lng = parseFloat($self.attr('data-lng')),
            _name = $self.attr('data-name');

          var info = new google.maps.InfoWindow(),
            mapBlock = new google.maps.Map($self[0], {
              zoom: 15,
              center: {lat: _lat, lng: _lng},
              scrollwheel: false
            });

          var image = _themeUrl + '/dist/images/pinche.png',
            point = {'lat': _lat, 'lng': _lng},
            marker = new google.maps.Marker({
              position: point,
              map: mapBlock,
              title: _name,
              icon: image
            });

          google.maps.event.addListener(marker, 'click', function () {
            info.setOptions({
              content: _name,
              size: new google.maps.Size(50, 50),
              position: point
            });
            info.open(mapBlock, marker);
          });

          google.maps.event.addDomListener(window, "resize", function () {
            var center = mapBlock.getCenter();
            google.maps.event.trigger(mapBlock, "resize");
            mapBlock.setCenter(center);
          });
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        //console.log('finalize home');
      }
    },
    // Inner templates
    'page_template_template_innerPages': {
      init: function () {
        // JavaScript to be fired on innerPages
        //console.log('init innerPages');

        var $customerSlider = $('#customerSlider');
        $customerSlider.slick({
          autoplay: true,
          autoplaySpeed: 4000,
          centerMode: true,
          speed: 800,
          initialSlide: 0,
          slidesToShow: 5,
          // variableWidth: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 690,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });

        var $customerSlider2 = $('#customerSlider2');
        $customerSlider2.slick({
          autoplay: true,
          autoplaySpeed: 4000,
          centerMode: true,
          speed: 800,
          initialSlide: 0,
          slidesToShow: 5,
          // variableWidth: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 690,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });
      }
      ,
      finalize: function () {
        // JavaScript to be fired on innerPages, after the init JS
        //console.log('finalize innerPages');
      }
    }
    ,
    // News templates
    'page_template_template_newsPage': {
      init: function () {
        // JavaScript to be fired on newsPages
        //console.log('init newsPages');
        var btn = $('.moreNews');

        btn.on('click', function (e) {
          e.preventDefault();
          UTIL.getMoreNews();
        });

      }
      ,
      finalize: function () {
        // JavaScript to be fired on newsPages, after the init JS
        //console.log('finalize newsPages');
      }
    }
    ,
    // Inner templates
    'empresa': {
      init: function () {
        // JavaScript to be fired on the empresa page
        //console.log('init empresa');
      }
      ,
      finalize: function () {
        // JavaScript to be fired on the empresa page, after the init JS
        //console.log('finalize empresa');
      }
    }
    
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    setHeight: function (container, disable) {
      var maxHeight = 0,
      //Get all the elements with class = col
        $childs = container.find('> div');
      //Loop all the columns
      $childs.each(function () {
        //Store the highest value
        if ($(this).height() > maxHeight) {
          maxHeight = $(this).height();
        }
      });
      //Set height
      if (disable) {
        $childs.height('auto');
      }
      else {
        $childs.height(maxHeight);
      }

    },
    loadContactMap: function () {
      var info = new google.maps.InfoWindow(),
        map = new google.maps.Map($('#contactGmaps')[0], {
          zoom: 7,
          center: {lat: -34.825654, lng: -58.397735},
          scrollwheel: false
        });
      var ps = [
        {'nombre': 'Planta 1', 'lat': -34.825654, 'lng': -58.397735},
        {'nombre': 'Planta 2', 'lat': -34.842507, 'lng': -58.413153}
      ];

      var bounds = new google.maps.LatLngBounds(); // Obtener limites

      $.each(ps, function () {
        var self = this,
          point = new google.maps.LatLng(self.lat, self.lng);

        bounds.extend(point); // Agregar puntos a los limites
        UTIL.getMapLayout(self, info, map);
      });

      map.fitBounds(bounds); // Centrar en los limites

      google.maps.event.addDomListener(window, "resize", function () {
        var center = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
      });
    },
    getMapLayout: function (p, info, map) {
      // get lat, lng
      // add Marker

      var image = _themeUrl + '/dist/images/pinche.png',
        point = {'lat': p.lat, 'lng': p.lng},
        marker = new google.maps.Marker({
          position: point,
          map: map,
          title: p.nombre,
          icon: image
        });

      google.maps.event.addListener(marker, 'click', function () {
        info.setOptions({
          content: p.nombre,
          size: new google.maps.Size(50, 50),
          position: point
        });
        info.open(map, marker);
      });
    },
    setImagesSrcset: function () {
      var $responsiveImages = $('img.responsiveImg'),
        winW = $(window).width();

      $responsiveImages.each(function () {
        var self = $(this),
          src;

        if (winW < 768) {
          src = self.attr('data-src-mobile');
        } else if (winW > 992) {
          src = self.attr('data-src-pc');
        } else {
          src = self.attr('data-src-tablet');
        }
        self.attr('src', src);

      });
    },
    initHeaderSlider: function () {

      var $slider = $('#mainSlider'),
        $slide = $slider.find('.slide'),
        topBarH = $('header').height(),
        initialSlide = 0,
        initialHeight = 550, // Height from -> https://www.edibleschoolyardnyc.org/
        winH = $(window).height();

      // Initial slide config
      if ($('body.home').length > 0) {
        initialHeight = winH - topBarH; // Window height without Top Bar height.
      }
      if ($('body.productos').length > 0) {
        initialSlide = 1;
      }
      if ($('body.tecnologia').length > 0) {
        initialSlide = 3;
      }

      setTimeout(function () {
        $slider.css('opacity', 1);
      }, 500);

      // $slide.height( initialHeight );
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        fade: true,
        speed: 800,
        initialSlide: initialSlide
      });

    },
    anchorScrollDetection: function () {
      var $links = $('.menu-item-type-custom'),
        _urlHash = window.location.hash;

      if (_urlHash !== '') {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 1);
        setTimeout(function () {
          UTIL.scrollToElement(_urlHash.replace('#_', ''));
        }, 1000);
      }

      $links.on('click', function (e) {
        e.preventDefault();

        var $self = $(this),
          $anchorName = $self.find('a').attr('href').split('#')[1],
          $parentLi = $self.closest('.sub-menu').closest('li'),
          _redirectUrl = $parentLi.find('> a').attr('href');

        if ($parentLi.hasClass('current_page_item')) {
          UTIL.scrollToElement($anchorName);
        } else {
          if ($self.find('a').attr('target') === '_blank') {
            window.open($self.find('a').attr('href'));
          } else {
            if (_redirectUrl === undefined) {
              _redirectUrl = $self.find('a').attr('href').split('#')[0];
            }
            window.location.href = _redirectUrl + '#_' + $anchorName;
          }
        }
      });
    },
    scrollToElement: function (a) {
      var e = $('#' + a);
      if (e.length === 0) {
        return 0;
      }
      var destination = e.offset().top;

      $("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 800, function () {
        window.location.hash = a;
      });
    },
    getMoreNews: function () {
      var $btn = $('.moreNews'),
        _cat = $btn.attr('data-category'),
        _page = parseInt($btn.attr('data-nextpage')),
        _lastPage = parseInt($btn.attr('data-max-pages')),
        $container = $('.newsContainer');

      if ($btn.hasClass('loading')) {
        return 0;
      }

      $btn.addClass('loading')
        .removeClass('noMore');

      if (_page === 0) {
        /**
         * Reset load more btn
         * Scroll top
         * Hide All appended News
         * Set pager to 2
         */
        var $ajaxLoadedNews = $('.ajaxLoaded'),
          _scrollTo = $('.newsPage').offset().top - 100,
          _waitTime = 1000;

        setTimeout(function () {
          $btn.removeClass('loading');
          $("html:not(:animated),body:not(:animated)").animate({
            scrollTop: _scrollTo
          }, _waitTime, function () {
            $ajaxLoadedNews.fadeOut('fast', function () {
              $ajaxLoadedNews.remove();
              $btn.attr('data-nextpage', 2);
            });
          });
        }, _waitTime);
      } else {
        jQuery.ajax({
          type: 'GET',
          url: myAjax.ajaxurl,
          data: {
            action: 'get_more_news',
            cat: _cat,
            paged: _page
          },
          success: function (response) {
            if (response === '') {
              /**
               * Agregar clase de no hay mas noticias
               * Poner bandera paginador a 0
               */
              $btn.addClass('noMore');
              $btn.attr('data-nextpage', 0);
            } else {
              /**
               * Agregar noticias al contenedor
               * fade in
               * Si es la ultima pagina mostrar ver menos
               * Sino aumentar contador de paginas
               */
              $container.append(response);
              $('.notShown').fadeIn(function () {
                if ((_page + 1) > _lastPage) {
                  $btn.addClass('noMore');
                  $btn.attr('data-nextpage', 0);
                } else {
                  $btn.attr('data-nextpage', _page + 1);
                }
              });
            }
          },
          error: function (response) {
            console.log('error', response);
          },
          complete: function () {
            /**
             * Hide Loader
             */
            $btn.removeClass('loading');
          }
        });
      }

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /**
   * On Resize Function
   */
  var resizeHandler = debounce(function () {
    var overlayMenu = $('.overlayMenu'),
      overlayMenuBlockContainer = $('.menuBlockContainer'),
      winW = $(window).width();

    //Desktop
    if (winW > 768) {
      UTIL.setHeight(overlayMenuBlockContainer);
    } else {
      UTIL.setHeight(overlayMenuBlockContainer, true);
    }

    UTIL.setImagesSrcset();


  }, 250);
  /**
   * On Scroll Function
   */
  var scrollHandler = debounce(function () {
    var winH = $(window).height(),
      winH3 = winH / 3,
      scrollPos = $(document).scrollTop(),
      $bttArrow = $('.bttArrow');

    if (scrollPos > winH3) {
      $bttArrow.fadeIn();
    } else {
      $bttArrow.fadeOut('fast');
    }

  }, 250);

  window.addEventListener('resize', resizeHandler);
  window.addEventListener('scroll', scrollHandler);

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this, args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  jQuery.fn.anchorAnimate = function (settings) {

    settings = jQuery.extend({
      urlChange: true,
      speed: 1100
    }, settings);

    return this.each(function () {
      var caller = this;
      $(caller).click(function (event) {
        event.preventDefault();
        var locationHref = window.location.href;
        var elementClick = $(caller).attr("href");

        var destination = $(elementClick).offset().top;
        $("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, settings.speed, function () {
          if (settings.urlChange) {
            window.location.hash = elementClick;
          }
        });
        return false;
      });
    });
  };

})(jQuery); // Fully reference jQuery after this point.
